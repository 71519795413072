import React, { useContext, useState, useEffect, lazy, Suspense } from "react";
// import MainSlider from "./Components/Slider/MainSlider";
// import Sliderfade from "./Components/Slider/Sliderfade";
// import Staticslider from "./Components/Slider/Staticslider";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import { useTheme, ThemeProvider } from './Components/Theme/ThemeContext';
// import Slider from "./Components/Slider/Slider";
import "./App.css";
import "./Assets/css/offcanvas.css";

import Paypal from "./Components/Checkout/Paypal";
import Popup from "./Components/PopupBanner/Popup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useLocalStorage from "use-local-storage";
import { useSelector } from "react-redux";
import ProductDetails from "./Pages/ProductDetails";

import Return from "./Components/Return/Return";
import ReturnView from "./Components/Return/ReturnView";

import ActivitieLoader from "./Components/Loader/ActivitieLoader";
import { Grid } from "@mui/material";
import PageLoader from "./Components/Loader/PageLoader";
// import ResetPassword from './Components/Authentication/Reset/ResetPassword';
// import OrderSummery from './Components/Orders/OrderSummery';
// import Return from './Components/Return/Return'
// import OrderCancel from "./Components/Orders/OrderCancel";
// import Success from './Components/Orders/PaymentSuccess'
// import Confirm from './Pages/Confirmation'
// import AccountPage from './Pages/Account';
// import ProductDetails from "./Pages/ProductDetails";
// import NotFound from "./Pages/NotFound";
// import ScrollToTop from "./Components/ScrollButton/ScrollToTop";
// import Authentication from "./Pages/Authentication";
// import ResetPass from "./Components/Authentication/Reset/ResetPass";
// import BlogDetails from "./Components/Blog/BlogDetails/BlogDetails";
// import TermsConditions from "./Pages/TermsConditions";
// import ShoppingCart from "./Components/ShoppingCart/ShoppingCart";
// import Home from "./Pages/Home";
// import About from "./Pages/About";
// import Shop from "./Pages/Shop";
// import Contact from "./Pages/Contact";
// import Blog from "./Pages/Blog";
// import Header from "./Components/Header/NavbarNew";
// import FloatingNav from "./Components/Header/FloatingNav";
// import Footer from "./Components/Footer/Footer";

const AccountManagerLayout = lazy(() =>
  import("./Components/Account/AccountManager")
);
const WishList = lazy(() => import("./Components/Account/WishList"));
const OrderCancel = lazy(() => import("./Components/Orders/OrderCancel"));
const AddressAdd = lazy(() => import("./Components/Address/AddressAdd"));
const OrderSearch = lazy(() => import("./Components/Orders/OrderSearch"));
const Order = lazy(() => import("./Components/Orders/Order"));

const AccountPage = lazy(() => import("./Pages/Account"));
const Home = lazy(() => import("./Pages/Home"));
const Header = lazy(() => import("./Components/Header/NavbarNew"));
const Footer = lazy(() => import("./Components/Footer/Footer"));
const About = lazy(() => import("./Pages/About"));
const Shop = lazy(() => import("./Pages/Shop"));
const Contact = lazy(() => import("./Pages/Contact"));
const Blog = lazy(() => import("./Pages/Blog"));

const FloatingNav = lazy(() => import("./Components/Header/FloatingNav"));
const ShoppingCart = lazy(() =>
  import("./Components/ShoppingCart/ShoppingCart")
);

// const ReturnView = lazy(() => import("./Components/Return/ReturnView"));
// const Return = lazy(() => import('./Components/Return/Return'));

// const Return = React.lazy(() => lazyRetry(() => import('./Components/Return/Return')));
// const ReturnView = React.lazy(() => lazyRetry(() => import('./Components/Return/ReturnView')));

const ResetPassword = lazy(() =>
  import("./Components/Authentication/Reset/ResetPassword")
);
const OrderSummery = lazy(() => import("./Components/Orders/OrderSummery"));

const Success = lazy(() => import("./Components/Orders/PaymentSuccess"));
const Confirm = lazy(() => import("./Pages/Confirmation"));
const NotFound = lazy(() => import("./Pages/NotFound"));
const ScrollToTop = lazy(() => import("./Components/ScrollButton/ScrollToTop"));
const Authentication = lazy(() => import("./Pages/Authentication"));
const ResetPass = lazy(() =>
  import("./Components/Authentication/Reset/ResetPass")
);
const BlogDetails = lazy(() =>
  import("./Components/Blog/BlogDetails/BlogDetails")
);
const TermsConditions = lazy(() => import("./Pages/TermsConditions"));

const App = () => {
  const isloading = useSelector((state) => state.Loader.isLoading);

  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  // const [theme, setTheme] = useLocalStorage(
  //   "theme",
  //   defaultDark ? "dark" : "light"
  // );
  const [theme, setTheme] = useLocalStorage(
    "theme",
    "light"
  );
  const user = useSelector((state) => state.Auth);
  console.log(user.token);

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  return (
    <div className="app" data-theme={theme}>
      {/* <Popup /> */}
      {/* <GuestToken/> */}
      <ScrollToTop />

      <BrowserRouter theme={theme}>
        <ActivitieLoader isloader={isloading} />
        <Header theme={theme} switchTheme={switchTheme} />
        <Suspense
          fallback={
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}} ><PageLoader/></div> 
          }
        >
        {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center"}} ><PageLoader/></div>  */}
          
            {/* <strong>
              <Grid container justifyContent="center">
                We’re fetching your dream deals—our servers just got caught
                window shopping...!
              </Grid>
            </strong> */}
          <Routes>
            <Route path="/" element={<Home theme={theme} />} />

            <Route path="/success" element={<Success />} />

            <Route path="/about" element={<About />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop?page=:pagenumber" element={<Shop />} />
            <Route path="/shop/Catagories/:catid" element={<Shop />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/product/:prid" element={<ProductDetails />} />
            <Route path="/loginSignUp/" element={<Authentication />} />
            <Route
              path="/account/:page"
              element={user?.token ? <AccountPage /> : null}
            />
            <Route path="/OrderCancel/:OrderNum" element={<OrderCancel />} />
            <Route path="/reset" element={<ResetPass />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/BlogDetails" element={<BlogDetails />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/cart" element={<ShoppingCart />} />
            <Route path="/checkout" element={<Paypal />} />
            <Route path="/confirmemail" element={<Confirm />} />
            <Route
              path="/orders/view/:paramOrderid"
              element={<OrderSummery />}
            />
            <Route path="/orders/return/:paramOrderid" element={<Return />} />
            <Route
              path="/orders/return/view/:paramOrderid"
              element={<ReturnView />}
            />
            <Route
              path="/Account"
              element={user?.token ? <AccountPage /> : <Authentication />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>

        <ToastContainer theme={theme} />
        <FloatingNav />
        <Footer theme={theme} />
      </BrowserRouter>

      {/* <Slider />
      <Sliderfade />
      <Staticslider />
      <MainSlider /> */}
    </div>
  );
};

export default App;
