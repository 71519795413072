import { useCallback } from 'react';

const UuiWon=()=>{
    const timestamp = new Date().getTime();
    const randomNumber = Math.random() * 10000000000000000;
    return `${timestamp}-${randomNumber.toFixed(0)}`;
    // return Math.random().toString(36).substr(2, 9);
}

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


  const dateTimeFormate = (data) => {
    const date = new Date(data);

    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    return formattedDate;
  };

  const dateTimeFormat= (data) => {
    const date = new Date(data);
  
    const formattedDate = date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Use 24-hour clock
    });
  
    return formattedDate;
  };




  function formatDateTime(dateString) {
    // Split the date string into date and time parts
    const [datePart, timePart] = dateString.split('T');
    
    // Extract year, month, and day from the date part
    const [year, month, day] = datePart.split('-');
    
    // Extract hours, minutes, and seconds from the time part
    const [hours, minutes, seconds] = timePart.split(':');
    
    // Extract milliseconds
    const milliseconds = seconds.split('.')[1];

    // Create a Date object
    const date = new Date(year, month - 1, day, hours, minutes, seconds.split('.')[0]);
    
    // Format the date as desired
    const formattedDate = `${padZero(date.getDate())}-${padZero(date.getMonth() + 1)}-${date.getFullYear()}`;
    
    return formattedDate;
  }

  //${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}.${milliseconds}
  
  // Helper function to pad zeros
  function padZero(value) {
    return (value < 10 ? '0' : '') + value;
  }
  
  // Example usage
//   const dateString = '2024-08-30T13:15:35.57';
//   console.log(formatDateTime(dateString));

export {
    UuiWon,
    formatDate,
    formatDateTime,
    dateTimeFormate,
    dateTimeFormat
}