import { useState, useEffect, React }  from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../Components/Toast/ShowToast";
import store from '../../App/store';
import { showLoader,hideLoader } from "../../Features/Loader/Loader";
import { addToCart,addToCartAsync,removeCartAsync } from "../../Features/Cart/cartSlice";
import { Navigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

  /**
    Universial function for for add to cart
 */
const handleAddToCart = (product) => {
  console.log('ADD TO CART',product);
    store.dispatch(showLoader());

    // const productToAdd = {
    //   productID: product.IM_ID,
    //   productName: product.IM_ItemName,
    //   productPrice: product.IGen_UnitCost,
    //   cartID:Math.random(),
    //   cartItemId:Math.random(),
    //   frontImg: product.IM_PicturePath.length?product.IM_PicturePath:'',
    //   productReviews: ''
    // }

    const productToAddcart={
        "CDtl_Id": 0,
        "CDtl_Cart_Id": 0,
        "CDtl_Product_Id": product.IM_ID,
        "CDtl_Variant_Id": "0",
        "CDtl_Quantity": 1,
        "CDtl_Price":product.IM_SALESPRICE,
        "CDtl_Status": null,
        "CDtl_CreatedBy": "admin",
        "CDtl_UpdatedBy": "admin",
        "Product": {
          "IM_PicturePath":product.IM_PicturePath,
          "IM_SALESPRICE":product.IM_SALESPRICE,
          "IM_ItemName": product?.IM_ItemName?product?.IM_ItemName:'',
          "IM_SalesTax":product?.IM_SalesTax
        }
    }
    
    console.log('ADD TO CART',productToAddcart);
    try{
        // store.dispatch(addToCart(productToAddcart));
        store.dispatch(addToCartAsync(productToAddcart));
        setTimeout(function(){
           store.dispatch(hideLoader());
        },100);
       

    } catch (e)  {
      console.log('ADD TO CART',e);
      showToast("Something went wrong", 'error')
    }
  };
 
  const handleBuyNow = async (product) => {
    console.log('ADD TO CART',product);
      store.dispatch(showLoader());

  
      const productToAddcart={
          "CDtl_Id": 0,
          "CDtl_Cart_Id": 0,
          "CDtl_Product_Id": product.IM_ID,
          "CDtl_Variant_Id": "0",
          "CDtl_Quantity": 1,
          "CDtl_Price":product.IM_SALESPRICE,
          "CDtl_Status": null,
          "CDtl_CreatedBy": "admin",
          "CDtl_UpdatedBy": "admin",
          "Product": {
            "IM_PicturePath":product.IM_PicturePath,
            "IM_SALESPRICE":product.IM_SALESPRICE,
            "IM_ItemName": product?.IM_ItemName?product?.IM_ItemName:'',
            "IM_SalesTax":product?.IM_SalesTax
          }
      }
      console.log('ADD TO CART',productToAddcart);
      try{
          // store.dispatch(addToCart(productToAddcart));
          store.dispatch(addToCartAsync(productToAddcart));
          store.dispatch(hideLoader());
        
      } catch (e)  {
        console.log('ADD TO CART',e);
        showToast("Something went wrong", 'error')
      }
    };

    const handleCartRemove = async (productId, cartId,fnr) => {
      store.dispatch(
        removeCartAsync({
          productID: productId,
          cartId: cartId,
        })
      );


      // setTimeout(() => {
      //   fnr(Math.random());
      // }, 500);
    };

    const useRemoveFromCart = () => {
      const queryClient = useQueryClient();
      const dispatch = useDispatch();
    
      const handleRemoveFromCart = async (productId, cartId, fnr) => {
        dispatch(removeCartAsync({ productID: productId, cartId: cartId }));
        await queryClient.invalidateQueries({ queryKey:  [
          "cartlist"
        ] });
    
      };
    
      return { handleRemoveFromCart };
    };
  export {
    handleAddToCart,
    handleBuyNow,
    handleCartRemove,
    useRemoveFromCart
  }