
function ReturnAmount(qty, unitPrice) {
    return qty * unitPrice;
}

function TotalRetrnAmount(items) {

    if (items?.length > 0) {
    //    alert(JSON.stringify(items));

    let total = items.reduce((acc, item) => {
        acc += ReturnAmount(item.Ecom_OR_IMQty, item.IM_SALESPRICE);
        return acc;
    }, 0);

    return total;

    //    alert(total);
    } else {
        return 0;
    }

}



export {
    ReturnAmount,
    TotalRetrnAmount
}