import React, { useEffect, useMemo, useState } from "react";
import "./ReturnView.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useQuery } from "@tanstack/react-query";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { getReturn } from "../../Api/Return";
import { selectCurrency } from "../../Features/Currency/Currency";
import success from "../../Assets/success.png";
import { useParams, useNavigate } from "react-router-dom";
// import {formatDateTime} from '../../Lib/Lib';
import NoImage from "../../Assets/no_image.jpg";
import { IMAGE_URL_BASE_PATH } from "../../App/GlobalEnv";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepIcon from "@mui/material/StepIcon";
import Avatar from "@mui/material/Avatar";

import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { color } from "framer-motion";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { dateTimeFormate } from "../../Lib/Lib";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import Product from "../Product/ProductMain/Product";
import { ReturnAmount, TotalRetrnAmount } from "../../Features/Return/Return";
import AccountGoback from "../Account/AccountGoback";
export default function ReturnView({ orderID, isCheckoutpage }) {
  const { paramOrderid } = useParams();
  const [scrollToTop] = useScrollToTop();
  let Navigate = useNavigate();
  const [DataReturn, setDataReturn] = useState([]);
  const steps = [
    "Ordered",
    "In Progress",
    "packed",
    "Shipped",
    "Dispatched",
    "Out for Delivery",
    "Delivered ",
  ];
  const stepsIn = [
    "Ordered",
    "In",
    "packed",
    "Shipp",
    "Dispat",
    "Out",
    "Delivered ",
  ];

  const CurrencySymbol = useSelector(selectCurrency);
  const [ActiveStep, setActiveStep] = useState([]);
  const finalid = paramOrderid ? paramOrderid : orderID;

  const {
    isPending,
    isError,
    data: OrderData,
    error,
  } = useQuery({
    queryKey: ["return", finalid],
    queryFn: async () => {
      const orders = await getReturn(finalid);

      return orders;
    },
    staleTime: 0,
    refetchInterval: 60000,
    refetchOnWindowFocus: true,
  });

  // console.log('returnData',returnData)

  const Total = useMemo(
    () => TotalRetrnAmount(DataReturn?.product),
    [DataReturn]
  );

  useEffect(() => {
    if (OrderData && OrderData.length > 0) {
      console.log("OrderData", OrderData);
      const returnData = OrderData.reduce(
        (acc, item) => {
          acc.Ecom_OR_ID = item.Ecom_OR_ID;
          acc.Ecom_OR_LI_id = item.Ecom_OR_LI_id;
          acc.Ecom_OR_IM_ID = item.Ecom_OR_IM_ID;
          acc.Ecom_OR_Number = item.Ecom_OR_Number;
          acc.Ecom_OR_Reason = item.Ecom_OR_Reason;
          acc.Ecom_OR_ReturnOrderNumber = item.Ecom_OR_ReturnOrderNumber;
          acc.Ecom_OR_Date = item.Ecom_OR_Date;
          acc.Ecom_OR_CreatedAt = item.Ecom_OR_CreatedAt;
          acc.Ecom_OR_CreatedBy = item.Ecom_OR_CreatedBy;
          acc.Ecom_OR_UpdatedAt = item.Ecom_OR_UpdatedAt;
          acc.Ecom_OR_UpdatedBy = item.Ecom_OR_UpdatedBy;
          item.product["Ecom_OR_IMQty"] = item.Ecom_OR_IMQty;
          acc.product.push(item.product);
          return acc;
        },
        {
          product: [],
        }
      );

      setDataReturn(returnData);
      console.log("TotalRetrnAmount", TotalRetrnAmount(returnData.product));
    } else {
      setDataReturn([]);
    }
  }, [OrderData]);

  //  alert(JSON.stringify(OrderData));

  return (
    <>
      <div className="container-d">
        <div className="d-flex">
          <AccountGoback />
        </div>
        <section className="mx-5 my-3">
          <h1>Return Status</h1>
          {/* <div>
            Return Ordered  Order#{" "}
            
          </div> */}
          {/* <p>{JSON.stringify(returnData)}</p> */}
        </section>

        <section className="">
          {/* <p>{JSON.stringify(OrderData?.Shipment)}</p> */}
          <>
            {true ? (
              <>
                <Card key={1} className="order_card_container">
                  <CardContent>
                    <Grid container gap={3}>
                      <Grid container className="card_content_header">
                        <Grid container gap={4} item xs={8} sm={5}>
                          <div>
                            <p style={{ fontWeight: "bold" }}>ORDER PLACED</p>
                            <p>{dateTimeFormate(DataReturn.Ecom_OR_Date)}</p>
                          </div>
                          <div>
                            <p style={{ fontWeight: "bold" }}>TOTAL</p>
                            <p>
                              {Total}
                              {/* {Returndata.Ecom_CO_currency} */}

                              {/* {Returndata.Ecom_CO_total_price} */}
                            </p>
                          </div>
                        </Grid>
                        <Grid
                          container
                          justifyContent="space-between"
                          gap={4}
                          item
                          xs={4}
                          sm={7}
                        >
                          <div>
                            <p style={{ fontWeight: "bold" }}>Reason</p>
                            <p>{DataReturn?.Ecom_OR_Reason}</p>
                          </div>
                          <div>
                            <p style={{ fontWeight: "bold" }}></p>
                            <p>{DataReturn?.Ecom_OR_Number} </p>
                            <p>{DataReturn?.Ecom_OR_ReturnOrderNumber}</p>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container paddingLeft={1} spacing={2}>
                        <Grid
                          container
                          gap={2}
                          direction="column"
                          item
                          xs={12}
                          sm={9}
                        >
                          <div className="row gap-1 my-2">
                            {DataReturn?.product?.map((itemd) => {
                              return (
                                <>
                                  <div className="col-12">
                                    <div className="row my-2">
                                      <div className="col-2">
                                        <Avatar
                                          sx={{ height: 70, width: 70 }}
                                          variant="square"
                                          src={
                                            typeof itemd?.IM_PicturePath ==
                                            "string"
                                              ? IMAGE_URL_BASE_PATH +
                                                itemd.IM_PicturePath
                                              : // ? product.IM_PicturePath
                                                NoImage
                                          }
                                          alt={itemd?.iM_ItemName}
                                        />
                                      </div>
                                      <div className="col-4">
                                        <p>{itemd?.IM_ItemName} </p>
                                        <p>
                                          {itemd?.Ecom_OR_IMQty} *{" "}
                                          {itemd?.IM_SALESPRICE}{" "}
                                        </p>
                                        <p>
                                          {ReturnAmount(
                                            itemd?.Ecom_OR_IMQty,
                                            itemd?.IM_SALESPRICE
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </>
            ) : (
              <div className="order_list_notfound">
                <button
                  style={{ height: "100%", maxHeight: 50, width: "fitContent" }}
                  className="btn-lg"
                >
                  OrderList Not Found
                </button>
              </div>
            )}
          </>
        </section>
      </div>
    </>
  );
}
